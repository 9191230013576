import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Concept- en techniekvalidatie middels prototyping – Stap 2 van onze aanpak"
        description="De realisatie van een nieuw softwareproduct staat of valt meestal met een concept of techniek. Daarom starten we met het maken van een prototype."
        ogImage="prototyping.png"
        translatedPagePath="/en/approach/prototyping/">
        <Link to="/nl/werkwijze/kick-off/" className="body__nav body__nav--left" title="Aanpakstap 1. Kick-off">
            Vorige stap
        </Link>

        <Link
            to="/nl/werkwijze/productrealisatie/"
            className="body__nav body__nav--right"
            title="Aanpakstap 3. Realisatie">
            Volgende stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Concept- <br /> en techniek&shy;validatie
                        </h2>
                        <div className="display__number">2</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            De realisatie van een nieuw software&shy;product staat of valt meestal met het valideren van{' '}
                            een concept of techniek. Daarom starten we eerst met het maken van een prototype.
                        </p>
                        <p>
                            In een flitsende periode gaan onze specialisten aan de slag om de belang&shy;rijkste{' '}
                            product&shy;risico's af te dekken en aan&shy;names te{' '}
                            <span className="highlight">valideren</span>. Dit doen we door een{' '}
                            <span className="highlight">proto&shy;type</span> te ontwikkelen waarin we enkel focussen op
                            mogelijke struikel&shy;blokken en de validatie van aannames.
                        </p>
                        <p>
                            De uit&shy;werking hiervan noemen we een <span className="highlight">proof-of-concept</span>
                            . Dit is een ruwe opzet van het uit&shy;eindelijke software&shy;product. Onze{' '}
                            opdracht&shy;gevers kunnen een proof-of-concept gebruiken om stake&shy;holders definitief{' '}
                            van het product&shy;idee te overtuigen.
                        </p>
                        <p>
                            Nadat we gezamenlijk het proof-of-concept hebben beoordeeld, en de feedback van stakeholders{' '}
                            hebben meegenomen, stellen we een goed doordacht en flexibel{' '}
                            <span className="highlight">ontwikkelplan</span> vast. Dit plan vormt de basis voor het
                            realiseren van een initiële versie van het software&shy;product.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iteraties van 1 week</li>
                            <li className="ion-md-arrow-dropright">2 &ndash; 6 weken</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 3 specialisten</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/nl/werkwijze/productrealisatie/"
                            name="Volgende stap: productrealisatie"
                            title="Aanpakstap 3. Realisatie"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
